





import { Component, Vue } from "vue-property-decorator";
import CommoditySalesRecord from "@/components/CommoditySalesRecord.vue";

@Component({
  name: "PurchaseCommoditySalesRecordList",
  components: {
    CommoditySalesRecord
  }
})
export default class PurchaseCommoditySalesRecordList extends Vue {
  from = "";

  async created() {
    debugger
    this.from = this.$route.params.from;
  }


};
